import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
//API Helper
import { ApiComunicator } from '../../helpers/ApiComunicator';

const loading = () => <div className="animated fadeIn pt-3 text-center">Cargando...</div>;
// Containers
const AppLayout = React.lazy(() => import('../../containers/AppLayout/AppLayout'));

// Pages
const LoginContainer = React.lazy(() => import('../../containers/Login/LoginContainer'));
const ForgotContainer = React.lazy(() => import('../../containers/Login/ForgotContainer'));
const ConfirmacionContainer = React.lazy(() => import('../../containers/Login/ConfirmacionContainer'));
const RegisterContainer = React.lazy(() => import('../../containers/Login/RegisterContainer'));
const ResetContainer = React.lazy(() => import('../../containers/Login/ResetContainer'));

class App extends Component {
  //---------------------------------------------------------------------------
  componentDidMount() {
    let pathname = window.document.location.pathname;
    if (pathname !== "/login") {
      //Si tiene guardados datos de una sesión, chequeo el token
      if (this.isAuthenticated()) {
        this.chequearToken()
      }
    }

  }
  componentWillUnmount() {
    localStorage.clear();
  }
  //---------------------------------------------------------------------------
  //Despues de verificar el login, traigo el token y lo guardo
  chequearToken = () => {
    let url = "/auth/checktoken";
    ApiComunicator(url, null, 'GET')
      .then(response => {
        if (response.status !== "ok") {
          //Borra SOLAMENTE si NO está OK la validación del token
          //Sino sigue noraml
          localStorage.clear()
          window.document.location.pathname = "/login"
        }
      })
      .catch(error => {
        //si devuelve error, eliminar datos si no valida Token
        localStorage.clear()
        window.document.location.pathname = "/login"
      })
  }
  //---------------------------------------------------------------------------
  isAuthenticated = () => {
    return localStorage.getItem('user_id') !== null && localStorage.getItem('user') !== null;
  }
  //---------------------------------------------------------------------------
  // onLogout: Lo heredan los componentes como propiedad para desloguear.
  //---------------------------------------------------------------------------
  onLogout = () => {
    localStorage.clear();
    return (<Redirect to="/login" />)
  }
  //---------------------------------------------------------------------------
  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      this.isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    )}
    />
  )
  //---------------------------------------------------------------------------
  // RESPUESTA
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <LoginContainer {...props} />} />
            <Route exact path="/forgot" name="Forgot Page" render={props => <ForgotContainer {...props} />} />
            <Route exact path="/confirmacion" name="Forgot Page" render={props => <ConfirmacionContainer {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <RegisterContainer {...props} />} />
            <Route path="/reset/:secCode" name="Reset Page" render={props => <ResetContainer {...props} />} />
            <this.PrivateRoute path="/" isuthenticated={this.isAuthenticated} component={props => <AppLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    );

  }
}

export default App;
